<template>
  <div class="container_margin">
    <!-- start of Export modal -->
    <b-modal
      size="sm"
      id="export-edit-legal-writer"
      centered
      hide-footer
      hide-header
    >
      <h5 class="text-blue font-weight-bold">{{ "Export To" }}</h5>

      <div class="row mt-2">
        <div class="col">
          <div
            class="row mt-1"
            v-for="(export_item, export_item_normal) in options_export"
            :key="'export_pdf' + export_item_normal"
          >
            <div class="col-2">
              <input
                :name="'pdf' + export_item_normal"
                :id="'pdf' + export_item_normal"
                type="checkbox"
                :value="export_item"
                v-model="chosenExports"
                class="form-check-input mx-auto"
              />
            </div>
            <div class="col-2">
              <span
                class="material-icons-outlined icon-export-blue justify-content-center"
              >
                {{ export_item.icon }}
              </span>
            </div>

            <div class="col">
              <b class="text-blue"> {{ export_item.name }} </b>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col align-self-center">
          <!-- <b-form-checkbox
            id="checkbox-1"
            v-model="status"
            name="checkbox-1"
            value="accepted"
            unchecked-value="not_accepted"
          >
            <small class="ms-2">{{ "Export with Letter Head" }} </small>
          </b-form-checkbox> -->
        </div>
        <!-- <div class="col align-self-center">
          <p></p>
        </div> -->
      </div>

      <div class="row">
        <div class="col">
          <button @click="export_content" class="btn btn-primary float-right">
            {{ "Export" }}
          </button>
        </div>
      </div>
    </b-modal>
    <!-- end of Export modal -->

    <PageLoader
      v-if="pageLoading"
      :loading="pageLoading">
    </PageLoader>

    <div v-else class="row">
      <div class="col-12 px-4">
        <div class="row">
          <div class="col-sm-4 col-md-7 col-xl-7 align-self-center">
            <h4 class="font-weight-bold mb-0">{{ docName | capitalize }}</h4>
          </div>

          <!-- <div class="col">
            <div class="row h-100 align-items-center">
              <div class="col">
                Save Changes Button
                <button
                  @click="saveChanges"
                  class="float-right btn btn-primary"
                  id="saveChangesButton"
                >
                  {{ "Save Changes" }}
                </button>

                Export Button
                <button
                  v-b-modal.export-edit-legal-writer
                  class="btn-primary-outlined btn float-right mx-3"
                >
                  {{ "Export" }}
                </button>
              </div>
            </div>
          </div> -->
        </div>

        <!-- Editor -->
        <div class="mt-4">
          <QuillEditor
            :htmlContent="document"
            @init-editor="initEditor"
            @update-editor="updateEditor"
            @save-changes="saveChanges"
          ></QuillEditor>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LES from "@/store/LES.js";
import QuillEditor from "@/components/QuillEditor.vue";
import { ExportData } from "@/store/utils";

export default {
  components: {
    PageLoader,
    QuillEditor,
  },

  data() {
    return {
      permissions: localStorage.permissions,
      pageLoading: true,
      pretrial_data: [],
      trial_data: [],
      timeout_timer: [],
      timer_count: [],
      duration: 20,
      interval: null,
      route_id: this.$route.params.id,
      current: null,
      document: null,
      docName: null,
      options_export: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      chosenExports: [],
      editor: null,
      updatedDocument: "",
    };
  },

  methods: {
    export_content() {
      if (this.chosenExports.length === 0) {
        this.$toast.error("Kindly select a format to export");
      }
      // console.log(this.chosenExports);

      var status = ExportData(
        this.editor.root.innerHTML,
        [
          this.chosenExports.find((element) => element.name === "DOCUMENT"),
          this.chosenExports.find((element) => element.name === "PDF"),
        ]
      );
      if (status[0]) {
        this.$toast.success("Successfully downloaded Document");
      }
      if (status[1]) {
        this.$toast.success("Successfully downloaded PDF");
      }

      this.chosenExports = [];
      this.$bvModal.hide("export-edit-legal-writer");
    },

    saveChanges({ content }) {
      console.log(content);
    },

    // editor
    initEditor({ editor, content }) {
      this.editor = editor;
      this.updatedDocument = content;
    },
    updateEditor({ content }) {
      this.updatedDocument = content;
    },
  },

  created() {
    LES.GetLES(this.$route.params.id)
      .then((response) => {
        this.pageLoading = false;

        response.data.data.lesValues.forEach((element) => {
          this.pretrial_data.push(element);
        });

        response.data.data.lesTrialValues.forEach((element) => {
          this.trial_data.push(element);
        });

        this.explanations = this.pretrial_data.map((item) => {
          return JSON
            .parse(item[Object.keys(item)[0]])
            .explaination;
        });

        this.trial_explanations = this.trial_data.map((item) => {
          return JSON
            .parse(item[Object.keys(item)[0]])
            .explaination;
        });

        // console.log("pre", this.pretrial_data);
        // console.log("param file", this.$route.params.file);

        if (this.$route.params.category == "preTrial") {
          this.current = this.pretrial_data[this.$route.params.file];
        } else {
          this.current = this.trial_data[this.$route.params.file];
        }

        const key = Object.keys(this.current)[0];

        this.docName = 
          (this.$route.params.category == "preTrial"
            ? key.replace("Les-", "")
            : key.replace("LesTrial-", ""))
          .replace(/_/g, " ");
        this.document = JSON.parse(this.current[key]).output;

        // // remove '-' from beginning of sentence
        // this.document = this.document.replaceAll(/\n\s*- /g, "\n");
        // change any number of digits with spaces & without '.' into digit with '.'
        this.document = this.document.replaceAll(/\n\s*(\d+)\s/g, "\n$1. "); 
        this.document = this.document.replaceAll(
          /\n(\d+)(?![\d\.)])/g,
          "\n$1. "
        );
        this.document = this.document.replaceAll("\n", "<br>");
        this.document = this.document.replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>");

        // //For citation
        // this.letter = this.letter.replaceAll(
        //   "THISSSSTHIIss",
        //   '<a href="https://www.google.com/"><span style="text-decoration:underline; color:#0000ff">google.com</span></a>'
        // );

        // sample to change color
        // this.document = this.document.replaceAll(
        //   /\*\*(.*?)\*\*/g,
        //   "<b><span class='ql-color-blue'>$1</span></b>"
        // );

        // console.log(this.document)
        // console.log("LesValues");
        // console.log(this.pretrial_data);

        // console.log("LesValues New");
        // console.log(JSON.parse(JSON.stringify(this.pretrial_data)));

        // console.log("lesTrialValues");
        // console.log(this.trial_data);

        // console.log("explanations");
        // console.log(this.explanations);

        // console.log("trial_explanations");
        // console.log(this.trial_explanations);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  destroyed() {
    clearInterval(this.interval);
    //   clearTimeout(this.timeout_timer);
  },

  beforeDestroy() {
    clearInterval(this.interval);
    //   this.timer_count = 0;
    //   clearTimeout(this.timeout_timer);
  },
};
</script>

<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}
/* table */

.container_margin {
  padding: 2rem;
}

.pad_left_40 {
  padding-left: 40px;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}
</style>
